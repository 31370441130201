
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="600px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" inline :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="充值金额" prop="rechargeAmount">
          <el-input v-model="formModel.rechargeAmount" placeholder="请输入充值金额" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="赠送金额" prop="giftAmount">
        <el-input v-model="formModel.giftAmount" placeholder="请输入赠送金额" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import chargingMarketingApi from "@/api/base/chargingMarketing";

export default {
  props: ["businessKey", "title"],
  computed: {
      ruleValidate (){
        var rules = null;
        rules = {
            rechargeAmount: [
                { required: true, message: "充值金额不能为空", trigger: "blur" }
            ],
            giftAmount: [
                { required: true, message: "赠送金额不能为空", trigger: "blur" }
            ],
            permanentN:[
              {required: true, message: "计价类型不能为空", trigger: "blur" }
            ]
        };
        return rules;
      }
  },
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      templateList: [],
      defaultPriceList:[],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              var createName = document.getElementById("userRealName").textContent;
              self.formModel.createName = createName;
              return chargingMarketingApi.add(self.formModel);
            } else {
              return chargingMarketingApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  created() {
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return chargingMarketingApi.create();
      } else {
        return chargingMarketingApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        //   if(!jsonData.data.permanent){
        //     self.formModel.termValidity = jsonData.data.termValidityArray;
        //   }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-panel {
  margin: 10px auto;
}
</style>